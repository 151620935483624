@font-face {
  font-family: "GilroyMedium";
  src: local("GilroyMedium"),
    url("./assets/fonts/Gilroy-Medium.ttf") format("truetype");
  font-weight: normal;
}

@font-face {
  font-family: "GilroySemiBold";
  src: local("GilroySemiBold"),
    url("./assets/fonts/Gilroy-SemiBold.ttf") format("truetype");
  font-weight: normal;
}

@font-face {
  font-family: "GilroyBold";
  src: local("GilroyBold"),
    url("./assets/fonts/Gilroy-Bold.ttf") format("truetype");
  font-weight: normal;
}

@font-face {
  font-family: "GilroyHeavy";
  src: local("GilroyHeavy"),
    url("./assets/fonts/Gilroy-Heavy.ttf") format("truetype");
  font-weight: normal;
}

body {
  margin: 0;
  font-family: 'GilroyMedium',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #F9711B;
  color: #FFE8D9;
  width: 100%;
  min-height: calc(100vh - 160px);
}

.app{
  display: block;
  margin: 80px auto 40px auto;
  width: 960px;
}

.part_one{
  display: inline-block;
  vertical-align: top;
  width: 450px;
  padding: 15px;
}

.mckup{
  display: block;
  margin: 50px auto;
  height: 620px;
}

.part_two{
  display: inline-block;
  vertical-align: top;
  width: 450px;
  padding: 15px;
}

.part_two .coming{
  margin-top: 60px;
  display: block;
  text-align: left;
  font-size: 16px;
  font-family: 'GilroySemiBold';
}

.part_two .logo{
  margin-top: 20px;
  width: 280px;
  display: block;
}

.part_two .text{
  margin-top: 30px;
  display: block;
  text-align: left;
  font-size: 22px;
  font-family: 'GilroyBold';
}

.part_two .mintext{
  margin-top: 15px;
  display: block;
  text-align: left;
  font-size: 15px;
  font-family: 'GilroyMedium';
  color:#FFF !important;
}

.part_two .mintext a{
  font-family: 'GilroyBold';
  color:#FF4000 !important;
}

.part_two .mintext strong{
  font-family: 'GilroyBold';
  color:#FFE8D9 !important;
}

.part_two .tags{
  margin-top: 20px;
  display: block;
  text-align: left;
  font-size: 64px;
  font-family: 'GilroyHeavy';
  text-transform: uppercase;
}

.part_two button{
  width: 280px;
  height: 55px;
  line-height: 50px;
  border-radius: 55px;
  text-align: center;
  color: #F9711B;
  background-color: #FFE8D9;
  font-size: 20px;
  font-family: 'GilroyBold';
  border: 0;
  cursor: pointer;
  display: block;
  margin-top: 30px;
  position: relative;
}

.part_two button:hover{
  background-color: #FFF;
}

.legal{
  margin: 0 auto;
  display: block;
  text-align: center;
  font-size: 12px;
  color: #333;
  padding-bottom: 20px;
  max-width: 80%;
}

.lg{
  position: absolute;
  top: 20px;
  right: 30px;
  font-size: 16px;
  text-align: right;
  cursor: pointer;
  font-family: 'GilroyBold';
}

@media screen and (max-width: 960px) {
  .app{
    margin: 40px auto;
    width: 100%;
  }

  .mckup{
    margin: 20px auto;
    width: 90vw;
    height: auto;
  }
  
  .part_two{
    display: block;
    width: calc(100% - 40px);
    overflow-x: hidden;
  }

  .part_two .coming{
    margin-top: 30px;
  }

  .part_two button{
    margin: 30px auto 0 auto;
  }

  .part_two .tags{
    font-size: 48px;
  }
}